export const questionPath = name => `/questions/${name}`;

export const PATHS = {
  HOME: '/',
  CATEGORIES: '/categories',
  QUESTIONS: '/questions/:categoryName',
}

export const progressStatus = {
  NOT_STARTED: 'not_started',
  IN_PROGRESS: 'in_progress',
  FINISHED: 'finished',
}