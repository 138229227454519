import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Dialog, DialogContent, DialogActions, Button, Typography, Link } from '@mui/material';

import styles from './FeedbackDialog.module.css';

import * as userActions from '../redux/modules/userStore';
import * as questionActions from '../redux/modules/questionStore';
import { PATHS } from '../helpers/enums';

class LastMessageDialog extends PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    score: PropTypes.number,
    closeLastMessageDialog: PropTypes.func,
  };

  static defaultProps = {
    open: false,
    score: 0,
    closeLastMessageDialog: () => {},
  };

  handleClose = () => {
    const { closeLastMessageDialog } = this.props;
    closeLastMessageDialog();
  }

  submit = () => {
    window.location.assign(PATHS.CATEGORIES);    
  }

  render() {
    const { open, score } = this.props;

    return(
      <Dialog open={open} onClose={() => this.handleClose()}>
        <DialogContent>
          <Typography className={styles.labelFeedback}>Well done! You made it through the whole level! </Typography><br/>
          <Typography className={styles.labelFeedback}>
            <b>Your score:</b> {score}
          </Typography>
          <Typography className={styles.labelFeedback}>
            Want to be the first to know when new quizzes launch? <Link href='https://forms.gle/qcffajjm6m41Ra1A6' underline="always">Fill out this quick form</Link> and we’ll keep you in the loop. Thanks!
          </Typography><br/>
          
        </DialogContent>
        <DialogActions>
          <Button className={styles.sendButton} onClick={() => this.submit()}>Continue</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default connect(
  () => ({}),
  dispatch => 
    bindActionCreators(
      { 
      ...userActions, 
      ...questionActions,
      }, 
    dispatch, 
))(LastMessageDialog);
