import axios from 'axios';

import { sendEvent } from '../../analytics'; 
import { DEFAULT_REQUEST_TIMEOUT } from '../../helpers/constants';
import { PATHS } from '../../helpers/enums';
import { updateTickets } from './userStore';

const LOAD_CATEGORIES = 'artzapp/LOAD_CATEGORIES';
const LOAD_CATEGORIES_SUCCESS = 'artzapp/LOAD_CATEGORIES_SUCCESS';
const LOAD_CATEGORIES_FAILED = 'artzapp/LOAD_CATEGORIES_FAILED';

const LOAD_QUESTION = 'artzapp/LOAD_QUESTION';
const LOAD_QUESTION_SUCCESS = 'artzapp/LOAD_QUESTION_SUCCESS';
const LOAD_QUESTION_FAILED = 'artzapp/LOAD_QUESTION_FAILED';

const LOAD_ANSWER = 'artzapp/LOAD_ANSWER';
const LOAD_ANSWER_SUCCESS = 'artzapp/LOAD_ANSWER_SUCCESS';
const LOAD_ANSWER_FAILED = 'artzapp/LOAD_ANSWER_FAILED';

const LOAD_FINAL_QUESTION_SUCCESS = 'artzapp/LOAD_FINAL_QUESTION_SUCCESS';

const CLOSE_FEEDBACK_DIALOG='artzapp/CLOSE_FEEDBACK_DIALOG';

const RESET_CATEGORY_COMPLETED_FLAG = 'artzapp/RESET_CATEGORY_COMPLETED_FLAG';

const domain = process.env.REACT_APP_DOMAIN;

const initialState = {
  categories: [],
  loadingCategories: false,
  question: {},
  loadingQuestion: false,
  answer: {},
  loadingAnswer: false,
  error: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_CATEGORIES: {
      return {
        ...state,
        categories: [],
        loadingCategories: true,
        error: false,
      }
    }
    case LOAD_CATEGORIES_SUCCESS: {
      return {
        ...state,
        loadingCategories: false,
        categories: action.result,
        question: {},
        error: false,
      }
    }
    case LOAD_CATEGORIES_FAILED: {
      return {
        ...state,
        loadingCategories: false,
        categories: [],
        error: true,
      }
    }
    case LOAD_QUESTION: {
      return {
        ...state,
        question: {},
        loadingQuestion: true,
        answer: {},
        error: false,
      }
    }
    case LOAD_QUESTION_SUCCESS: {
      return {
        ...state,
        loadingQuestion: false,
        question: action.result,
        error: false,
      }
    }
    case LOAD_QUESTION_FAILED: {
      return {
        ...state,
        loadingQuestion: false,
        question: {},
        error: true,
      }
    }
    case LOAD_ANSWER: {
      return {
        ...state,
        loadingAnswer: true,
        answer: {},
        error: false,
      }
    }
    case LOAD_ANSWER_SUCCESS: {
      return {
        ...state,
        loadingAnswer: false,
        answer: action.result,
        error: false,
      }
    }
    case LOAD_FINAL_QUESTION_SUCCESS: {
      return {
        ...state,
        loadingAnswer: false,
        error: false,
      }
    }
    case LOAD_ANSWER_FAILED: {
      return {
        ...state,
        loadingAnswer: false,
        answer: {},
        error: true,
      }
    }
    case CLOSE_FEEDBACK_DIALOG: {
      return {
        ...state,
        question: {...state.question, ask_feedback: false }
      }
    }
    case RESET_CATEGORY_COMPLETED_FLAG: {
      const categories = state.categories.map(category => { 
        if (category.id === action.result.categoryId) {
          return { ...category, is_completed: false };
        }
        return category;
      })

      return {
        ...state,
        categories,
      }
    }
    
    default:
  }
  return state;
}

export const loadCategories = (timeout = DEFAULT_REQUEST_TIMEOUT) => async (dispatch) => {
  dispatch({ type: LOAD_CATEGORIES });

  await axios.get(`${domain}/api/quiz/categories/`, { withCredentials: true })
              .then(result => setTimeout(() => dispatch({ type: LOAD_CATEGORIES_SUCCESS, result: result.data }), timeout) )
              .catch(error => dispatch({ type: LOAD_CATEGORIES_FAILED, error }));
};

export const getQuestion = (attemptId, timeout = DEFAULT_REQUEST_TIMEOUT) => async (dispatch) => {
  dispatch({ type: LOAD_QUESTION });

  const res = await axios.get(`${domain}/api/quiz/attempts/${attemptId}/next-question`, { withCredentials: true })
              .then(result => { 
                  setTimeout(() => dispatch({ type: LOAD_QUESTION_SUCCESS, result: result.data }), timeout)
                  return result.data;
                })
              .catch(error => dispatch({ type: LOAD_QUESTION_FAILED, error }));
  sendEvent({ category: 'Question', action: 'Received', label: res.text });
};

export const submitAnswer = (attemptId, questionId, answer) => async (dispatch) => {
  dispatch({ type: LOAD_ANSWER });
  await axios.post(`${domain}/api/quiz/attempts/${attemptId}/submit-answer`, { question_id: questionId, answer_id: answer.id }, { withCredentials: true })
              .then(result => { 
                const { is_correct: isCorrect, text, tickets, title, question, choose_answer: chooseAnswer, right_answer: rightAnswer } = result.data;
                dispatch(updateTickets(tickets));
                setTimeout(() => dispatch({ type: LOAD_ANSWER_SUCCESS, result: { isCorrect, text, title, question, chooseAnswer, rightAnswer, tickets } }), DEFAULT_REQUEST_TIMEOUT);
              })
              .catch(error => dispatch({ type: LOAD_ANSWER_FAILED, error }));

  sendEvent({ category: 'Question', action: 'Answer', label: answer.text });
};

export const submitFinalAnswer = (attemptId, questionId, answer) => async (dispatch) => {
  dispatch({ type: LOAD_ANSWER });
  await axios.post(`${domain}/api/feedback/attempts/${attemptId}/final-answer`, { question_id: questionId, answer_id: answer.id }, { withCredentials: true })
              .then(() => {
                window.location.replace(PATHS.CATEGORIES);
                setTimeout(() => {
                  dispatch({ type: LOAD_FINAL_QUESTION_SUCCESS })
                }, DEFAULT_REQUEST_TIMEOUT);
              })
              .catch(error => dispatch({ type: LOAD_ANSWER_FAILED, error }));

  sendEvent({ category: 'Question', action: 'Final', label: answer.text });
};

export const closeFeedbackDialog = () => async (dispatch) => dispatch({ type: CLOSE_FEEDBACK_DIALOG });

export const resetCategoryCompletedFlag = categoryId => async (dispatch) => dispatch({ type: RESET_CATEGORY_COMPLETED_FLAG, result: { categoryId } });
