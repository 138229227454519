import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate } from 'react-router-dom';
import { sample } from 'lodash';
import ReactConfetti from 'react-confetti';

import { Button, Box, CircularProgress, Typography, LinearProgress } from '@mui/material';
import styles from './QuizScreen.module.css';

import FeedbackDialog from '../components/FeedbackDialog';

import { PATHS } from '../helpers/enums';
import { getAttemptId, isPathEnabled, getCategory } from '../helpers/helper';

import Zeek from '../assets/images/zeek/zeek.png';
import ZeekSunglasses from '../assets/images/zeek/zeekSunglasses.svg';
import ZeekSombrero from '../assets/images/zeek/zeekSombrero.svg';
import ZeekMohawk from '../assets/images/zeek/zeekMohawk.svg';

import ZeekCorrect from '../assets/images/zeek/zeekCorrectAnswer.svg';
import ZeekIncorrect from '../assets/images/zeek/zeekIncorrectAnswer.svg';
import XIncorrect from '../assets/images/xIncorrect.svg';
import CheckCorrect from '../assets/images/checkCorrect.svg';
import QuestionBg from '../assets/images/quiz/question.png';
import YouChooseBg from '../assets/images/quiz/you-choose.png';

import * as questionActions from '../redux/modules/questionStore';
import * as userActions from '../redux/modules/userStore';

import { sendPageView } from '../analytics';
import TextCircle from '../components/TextCircle';
import GreetingDialog from '../components/GreetingDialog';
import LastMessageDialog from '../components/LastMessageDialog';

class QuizScreen extends PureComponent {
  static propTypes = {
    answer: PropTypes.shape({ title: PropTypes.string, text: PropTypes.string, isCorrect: PropTypes.bool, chooseAnswer: PropTypes.string, tickets: PropTypes.number }),
    question: PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string,
      finished: PropTypes.bool,
      show_special: PropTypes.bool,
      ask_feedback: PropTypes.bool,
      is_final: PropTypes.bool,
      title: PropTypes.string,
      answers: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, text: PropTypes.string })),
      attempted_questions_count: PropTypes.number
    }),
    getQuestion: PropTypes.func,
    submitAnswer: PropTypes.func,
    submitFinalAnswer: PropTypes.func,
    updateAttemptedQuestions: PropTypes.func,
    loadingQuestion: PropTypes.bool,
    loadingAnswer: PropTypes.bool,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        text: PropTypes.string,
        is_enabled: PropTypes.bool,
        is_completed: PropTypes.bool,
        hexcolor: PropTypes.string,
        order: PropTypes.number
      })
    ),
    loadCategories: PropTypes.func,
    loadingCategories: PropTypes.bool,
    loadStart: PropTypes.func,
    userProgress: PropTypes.shape({ tickets: PropTypes.number, loaded: PropTypes.bool, attempts: PropTypes.arrayOf(PropTypes.shape({ category_id: PropTypes.number, id: PropTypes.number })) }),
  };

  static defaultProps = {
    answer: {},
    question: {},
    getQuestion: () => { },
    submitAnswer: () => { },
    submitFinalAnswer: () => { },
    loadCategories: () => { },
    updateAttemptedQuestions: () => { },
    loadingQuestion: false,
    loadingAnswer: false,
    loadingCategories: false,
    categories: [],
    loadStart: () => { },
    userProgress: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      windowHeight: document.documentElement.clientHeight * 0.01 * 100,
      windowWidth: document.documentElement.clientWidth * 0.01 * 100,
      category: {},
      greetingDiloagOpen: false,
      lastMessageDiloagOpen: false
    };
  };

  componentDidMount() {
    this.loadQuestion();

    // preload images
    const zeek = new Image();
    zeek.src = Zeek;

    const zeekCorrect = new Image();
    zeekCorrect.src = ZeekCorrect;

    const zeekIncorrect = new Image();
    zeekIncorrect.src = ZeekIncorrect;

    const correct = new Image();
    correct.src = CheckCorrect

    const incorrect = new Image();
    incorrect.src = XIncorrect;

    window.addEventListener('resize', () => this.handleWindowResize());

    sendPageView();
  };

  componentDidUpdate(prevProps) {
    this.loadQuestion();
    const { category } = this.state;
    const { question, updateAttemptedQuestions } = this.props;
    if (question && prevProps.question !== question) {
      updateAttemptedQuestions({category, count: question.attempted_questions_count});
      if(question.attempted_questions_count && question.attempted_questions_count%20 === 0){
        this.setState({greetingDiloagOpen: true});
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize())
  }

  loadQuestion = () => {
    const { userProgress, categories, loadStart, question, getQuestion, loadingQuestion, loadCategories, loadingCategories } = this.props;
    let attemptId;

    if (userProgress.loaded) {
      if (!categories.length && !loadingCategories) {
        loadCategories(0);
        return;
      }

      const category = getCategory(categories);
      if (!category) return;

      attemptId = getAttemptId(userProgress, category);
      if (!attemptId) {
        loadStart(category.id, 0);
      }
    }

    if (attemptId && !question.text && !question.finished && !loadingQuestion) {
      const category = getCategory(categories);
      this.setState({
        category
      })
      attemptId = getAttemptId(userProgress, category);
      getQuestion(attemptId, 100);
    }

    if(question.finished){
      this.setState({
        lastMessageDiloagOpen: true
      });
    }
  }

  shouldRenderProgressScreen = () => {
    const { question, loadingAnswer, loadingQuestion, loadingCategories } = this.props;
    return loadingQuestion || loadingAnswer || loadingCategories || (!question.text && !question.finished);
  };

  getZeekImage = question => {
    if (question.question) return question.isCorrect ? ZeekCorrect : ZeekIncorrect ;
    return sample([ZeekSunglasses, ZeekSombrero, ZeekMohawk]);
  }

  handleWindowResize = () => {
    this.setState({
      windowHeight: document.documentElement.clientHeight * 0.01 * 100,
      windowWidth: document.documentElement.clientWidth * 0.01 * 100
    })
  }

  handleSubmitAnswer = (attemptId, questionId, answer, isFinal) => {
    const { submitAnswer, submitFinalAnswer, question, updateAttemptedQuestions } = this.props;
    const { category } = this.state;

    if (isFinal) {
      submitFinalAnswer(attemptId, questionId, answer);
      return;
    }
        
    submitAnswer(attemptId, questionId, answer);
    updateAttemptedQuestions({category, count: question.attempted_questions_count + 1});
  }

  closeDialog = () => {
    this.setState({greetingDiloagOpen: false});
  }

  closeLastMessageDialog = () => {
    this.setState({lastMessageDiloagOpen: false});
  }

  renderAnswerScreen() {
    const { answer, getQuestion, categories, userProgress } = this.props;

    const category = getCategory(categories);
    const attemptId = getAttemptId(userProgress, category);
    const zeekImage = this.getZeekImage(answer);

    return (
      <>
        <TextCircle content={answer} />
        <div className="section">
          <div className="questionOptions" style={{ position: 'relative', flex: '0 0 70%' }}>
            <Box component='img' className={styles.youChoose} src={YouChooseBg} alt='ZAPP' />
            <div className={styles.youChooseContent}>
              <Typography className={styles.heading}>You chose:</Typography>
              <Typography className={styles.content}>{answer?.chooseAnswer}</Typography>
            </div>
          </div>
          <div className="zeekBox" style={{ flex: "0 0 30%" }}>
            <Typography>{answer?.title}</Typography>
            <Box component='img' className={styles.zeek} src={zeekImage} alt='ZAPP' />
          </div>
        </div>
        <Button variant="contained" className={styles.continueButton} onClick={() => getQuestion(attemptId)}>Continue</Button>
      </>
    )
  };

  renderQuestionScreen() {
    const { question, categories, userProgress } = this.props;

    const { windowHeight, windowWidth, greetingDiloagOpen, lastMessageDiloagOpen } = this.state;

    const category = getCategory(categories);
    const attemptId = getAttemptId(userProgress, category);
    const zeekImage = ((question.attempted_questions_count + 1) % 5 === 0 && question.attempted_questions_count > 0) ? this.getZeekImage(question) : Zeek;

    return (
      <>
        {question && !question.finished && 
          <>
            <div className="questionBox">
              <Box component='img' className="questionBg" src={QuestionBg} alt='ZAPP' />
              <div className='contentBox'>
                <Typography className="contentText">{question.text}</Typography>
              </div>
            </div>
            <div className="section">
              <div className="questionOptions">
                <Typography className="sectionHeading">Select one</Typography>
                <div className={styles?.questionAnswers}>
                  {question?.answers?.map(answer =>
                    <Button
                      // type='button' 
                      key={answer.id}
                      variant="contained"
                      className={styles.questionAnswer}
                      onClick={() => this.handleSubmitAnswer(attemptId, question.id, answer, question.is_final)}>
                      {answer.text}
                    </Button>)
                  }
                </div>
              </div>
              <div className="zeekBox">
                <Typography>{question?.title}</Typography>
                <Box component='img' className={styles.zeek} src={zeekImage} alt='ZAPP' />
              </div>
            </div>
            <FeedbackDialog open={question.ask_feedback} attemptId={attemptId} />
            <GreetingDialog open={greetingDiloagOpen} closeFeedbackDialog={this.closeDialog} />
          </>
        }
        {question.is_final && <ReactConfetti width={windowWidth} height={windowHeight} />}
        <LastMessageDialog open={lastMessageDiloagOpen} score={userProgress?.tickets} closeLastMessageDialog={this.closeLastMessageDialog} />
      </>
    )
  };

  render() {
    const { answer, question, categories, userProgress } = this.props;
    const { category } = this.state;
    const count = userProgress?.attempts?.filter(ele => ele.category_id === category?.id)?.[0]?.questions_answered
    if (categories.length && !isPathEnabled(categories)) return <Navigate replace to={PATHS.CATEGORIES} />;

    if (this.shouldRenderProgressScreen()) return <CircularProgress className={styles.circularProgress} />;


    // if(count > 0 && count%20 === 0 && ){
    //   this.setState({greetingDiloagOpen: true});
    // }

    return(
      <div className="screen">
        {question && !question.finished &&
          <>
            <div className='level-box'>
              <span><span style={{textTransform: 'capitalize'}}>{category.text}</span> Level {Math.ceil((count + 1)/20)}</span>
              <span>{(question.attempted_questions_count%20) + 1}/20 (100)</span>
            </div>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <Box sx={{ width: '100%', mb: 2}}>
                <LinearProgress variant="determinate" sx={{ height: '10px', borderRadius: '5px'}} value={Math.round(count ? (count%20)*5 : 0)} />
              </Box>
              {/* <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(count ?
                  (count%20)*5 : 0
                )}%`}</Typography>
              </Box> */}
            </Box>
          </>
        }
        {answer.title ? this.renderAnswerScreen() : this.renderQuestionScreen()}
      </div>
    )
  }
}

export default connect(
  state =>
  ({
    question: state.questionStore.question,
    loadingQuestion: state.questionStore.loadingQuestion,
    answer: state.questionStore.answer,
    loadingAnswer: state.questionStore.loadingAnswer,
    categories: state.questionStore.categories,
    loadingCategories: state.questionStore.loadingCategories,
    userProgress: state.userStore.userProgress,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...questionActions,
        ...userActions,
      },
      dispatch,
    ))(QuizScreen);