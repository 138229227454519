import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { orderBy } from 'lodash';

import { Typography, CircularProgress, Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material';
import { Link, Navigate } from 'react-router-dom';
import styles from './CategoriesScreen.module.css';

import { questionPath } from '../helpers/enums';
import { getAttemptId } from '../helpers/helper';

import CategoriesCircle from '../components/CategoriesCircle';
import * as questionActions from '../redux/modules/questionStore';
import * as userActions from '../redux/modules/userStore';

import { sendPageView, sendEvent } from '../analytics';
import TriviaQuiz from '../assets/images/trivia-quiz.png';

class CategoriesScreen extends PureComponent {
  static propTypes = {
    loadCategories: PropTypes.func,
    loadingCategories: PropTypes.bool,
    categories: PropTypes.arrayOf(
                  PropTypes.shape({
                    id: PropTypes.number,
                    text: PropTypes.string,
                    is_enabled: PropTypes.bool,
                    is_completed: PropTypes.bool,
                    hexcolor: PropTypes.string,
                    order: PropTypes.number
                  })
                ),
    userProgress: PropTypes.shape({ tickets: PropTypes.number, loaded: PropTypes.bool, attempts: PropTypes.arrayOf(PropTypes.shape({ category_id: PropTypes.number, id: PropTypes.number })) }),
    loadStart: PropTypes.func,
  };

  static defaultProps = {
    loadCategories: () => {},
    loadingCategories: false,
    categories: [],
    userProgress: {},
    loadStart: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      category: {},
      open: false,
    };
  };

  componentDidMount() {
    const { loadCategories } = this.props
    loadCategories();
    sendPageView();
  };

  componentDidUpdate() {
    this.loadCategories();
  }

  loadCategories = () => {
    const { loadCategories, categories, loadingCategories, userProgress } = this.props;
    if (userProgress.loaded && !categories.length && !loadingCategories) loadCategories(0);
  }

  renderResetProgressDialog = () => {
    const { userProgress } = this.props;
    const { category, open } = this.state;

    const attemptId = getAttemptId(userProgress, category);
    if(!attemptId) return <Navigate replace to={questionPath(category.text)}/>;

    return (
    <Dialog open={open} onClose={() => this.setState({ open: false, category: {}})}>
      <DialogTitle>Reset progress</DialogTitle>
      <DialogContent>
        <Typography>Do you want to reset your progress?</Typography>
      </DialogContent>
      <DialogActions>
        <Link className={styles.dialogButton} onClick={() => this.continueQuiz(category)} to={questionPath(category.text)}>No</Link>
        <Link className={styles.dialogButton} onClick={() => this.resetProgress(category)} to={questionPath(category.text)}>Yes</Link>
      </DialogActions>
    </Dialog>
    )
  }

  resetProgress = category => {
    const { loadStart } = this.props;
    loadStart(category.id, 0);
    this.getCategoriesQuestion(category);
  }

  continueQuiz = category => this.getCategoriesQuestion(category);

  getCategoriesQuestion = category => {
    this.setState({ open: false, category: {} });
    sendEvent({ category: 'Category', action: 'Select', label: category.text });
  }

  render() {
    const { categories, loadingCategories } = this.props;
    const { open } = this.state;

    if (loadingCategories || !categories.length) return <CircularProgress className={styles.circularProgress} />;

    return(
      <div className={styles.categoriesScreen}>
        <img src={TriviaQuiz} alt="trivia-quiz" className={styles.categoriesTitleLogo} />
        <Typography className={styles.categoriesTitle}>Choose an art form to get started</Typography>
        <div className={styles.categoriesOptions}>
          {orderBy(categories, ['order']).map(category => 
            <CategoriesCircle 
              onClick={() => this.setState({ category, open: true })} 
              key={category.id} 
              name={category.text} 
              disabled={!category.is_enabled}
              backgroundColor={category.hexcolor}/>
            )
          }
        </div>
        {open && this.renderResetProgressDialog()}
      </div>
    )
  }
}

export default connect(
  state => 
  ({ loadingCategories: state.questionStore.loadingCategories, categories: state.questionStore.categories, userProgress: state.userStore.userProgress }), 
  dispatch => 
    bindActionCreators(
      { 
      ...questionActions,
      ...userActions,
      }, 
    dispatch,
))(CategoriesScreen);