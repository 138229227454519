export const getCategory = categories => {
  const currentPathName = window.location.pathname;
  const lastParam = currentPathName.substring(currentPathName.lastIndexOf('/') + 1);

  return categories.find(ct => ct.text.trim() === lastParam.trim());
};

export const isPathEnabled = categories => {
  const category = getCategory(categories);

  if (!category || !category.is_enabled) return false;

  return true;
};

export const getAttemptId = (userProgress, category) => {
  const { attempts } = userProgress;
  const attempt =  attempts && attempts.find(att => att.category_id === category.id);

  return attempt && attempt.id;
};
