import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Typography, Box } from '@mui/material';
import classNames from 'classnames';
import styles from './EntryScreen.module.css';

// import EntryScreenLogo from '../assets/images/entryLogo.svg';
import EntryScreenLogo from '../assets/images/logo/horizontal-logo-FFFAE8.png';
import { PATHS } from '../helpers/enums';

import { sendPageView } from '../analytics';

export default class EntryScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayClickAnywayText: false,
    };
  };

  componentDidMount() {
    setTimeout(() => this.setState({ displayClickAnywayText: true }), 3000);

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    sendPageView();
  };

  render() {
    const { displayClickAnywayText } = this.state;

    return(
      <Box component='div' className={styles.page}>
        <img src="./bitmap/entry-screen-yellow.png" alt="bitmap-yellow" className={styles.yellowBitmap} />
        <img src="./bitmap/entry-screen-green.png" alt="bitmap-green" className={styles.greenBitmap} />
        <img src="./bitmap/entry-screen-orange.png" alt="bitmap-orange" className={styles.orangeBitmap} />
        <Link className={styles.entryScreen} to={PATHS.CATEGORIES}>
          <Box component='img' className={styles.entryScreenLogo} src={EntryScreenLogo} alt='Welcome!' />
          <Typography 
            className={classNames(styles.clickText, styles.blink, styles.hide, { [styles.show]: displayClickAnywayText }) }>
            Click anywhere<br/> to begin
          </Typography>
        </Link>
      </Box>
      )
  }
}
