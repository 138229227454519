import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Typography, Box } from '@mui/material';
import styles from './TextCircle.module.css';

import WrongAanswer from '../assets/images/quiz/wrong-answer.png';
import CorrectAanswer from '../assets/images/quiz/correct-answer.png';
import SmallTicket from './SmallTicket';

export default class TextCircle extends PureComponent {
  static propTypes = {
    content: PropTypes.shape({ title: PropTypes.string, text: PropTypes.string, isCorrect: PropTypes.bool, question: PropTypes.string, rightAnswer: PropTypes.string, chooseAnswer: PropTypes.string}),
  };

  static defaultProps = {
    content: {},
  };

  renderCircleWithTitle() {
    const { content } = this.props;

    return (
      <div className="questionBox">
          <Box component='img' className="questionBg" src={content?.isCorrect ? CorrectAanswer : WrongAanswer} alt='ZAPP'/>
          
          <div className="contentBox">
            <Typography className="contentText questionText">{content?.question}</Typography>
            <Typography className={styles.rightAnswer}>{content?.text}</Typography>
            <div className='answerTicketWrapper'>
              <SmallTicket tickets={content.isCorrect ? '+100' : '0'}/>
            </div>
          </div>
        </div>
      // <div className={classNames(styles.circle, styles.hasTitle, {[styles.isWrong]: !content.isCorrect, [styles.isCorrect]: content.isCorrect} )}>
      //   <div>
      //     <Typography className={classNames(styles.contentText, styles.title)}>{content.title}</Typography>
      //     <Typography className={classNames(styles.contentText, styles.description)}>{content.text}</Typography>
      //   </div>
      // <BigTicket isCorrect={content.isCorrect}/>
      // </div>
    );
  }

  render() {
    
    return this.renderCircleWithTitle();

    // return(
    // <div className={classNames(styles.circle, styles.isQuestion )}>
    //   <Typography className={styles.questionContect}>{content.text}</Typography>
    // </div>
    // )
  }
}
