import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

class GreetingDialog extends PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    closeFeedbackDialog: PropTypes.func,
  };

  static defaultProps = {
    open: false,
    closeFeedbackDialog: () => { },
  };

  // constructor(props) {
  //   super(props);
  //   // this.state = {
  //   //   stars: 0,
  //   //   text: ''
  //   // };
  // };

  handleClose = () => {
    const { closeFeedbackDialog } = this.props;
    closeFeedbackDialog();
  }

  submit = () => {

  }

  render() {
    const { open } = this.props;

    return (
      <Dialog open={open} onClose={() => this.handleClose()}>
        <DialogTitle>Congratulations !, You have completed level.</DialogTitle>
        <DialogContent>
          <div>You can sign-in/sign-up for save your progress.</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.submit()}>Sign In</Button>
          <Button onClick={() => this.submit()}>Sign Up</Button>
          <Button onClick={() => this.handleClose()}>Continue</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default GreetingDialog