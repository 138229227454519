import axios from 'axios';

import { sendEvent } from '../../analytics'; 
import { DEFAULT_REQUEST_TIMEOUT } from '../../helpers/constants';

const LOAD_PROGRESS = 'artzapp/LOAD_PROGRESS';
const LOAD_PROGRESS_SUCCESS = 'artzapp/LOAD_PROGRESS_SUCCESS';
const LOAD_PROGRESS_FAILED = 'artzapp/LOAD_PROGRESS_FAILED';
const LOAD_START = 'artzapp/LOAD_START';
const LOAD_START_SUCCESS = 'artzapp/LOAD_START_SUCCESS';
const LOAD_START_FAILED = 'artzapp/LOAD_START_FAILED';
const UPDATE_TICKETS = 'artzapp/UPDATE_TICKETS';
const UPDATE_ATTEMPTED_QUESTIONS = 'artzapp/UPDATE_ATTEMPTED_QUESTIONS';
const SUBMIT_FEEDBACK_FAILED = 'artzapp/SUBMIT_FEEDBACK_FAILED';

const domain = process.env.REACT_APP_DOMAIN;

const initialState = {
  userProgress: {},
  loadingProgress: false,
  error: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_PROGRESS: {
      return {
        ...state,
        userProgress: {},
        loadingProgress: true,
        error: false,
      }
    }
    case LOAD_PROGRESS_SUCCESS: {
      return {
        ...state,
        userProgress: action.result,
        loadingProgress: false,
        error: false,
      }
    }
    case LOAD_PROGRESS_FAILED: {
      return {
        ...state,
        userProgress: {},
        loadingProgress: false,
        error: true,
      };
    }
    case LOAD_START: {
      return {
        ...state,
        userProgress: {},
        loadingProgress: true,
        error: false,
      }
    }
    case LOAD_START_SUCCESS: {
      return {
        ...state,
        userProgress: action.result,
        loadingProgress: false,
        error: false,
      }
    }
    case LOAD_START_FAILED: {
      return {
        ...state,
        userProgress: {},
        loadingProgress: false,
        error: true,
      };
    }
    case UPDATE_TICKETS: {
      const result = { ...state.userProgress, tickets: action.result }
      return {
        ...state,
        userProgress: result,
      };
    }
    case UPDATE_ATTEMPTED_QUESTIONS: {
      const result = { ...state.userProgress };
      if(action?.result){
        const { category, count } = action?.result || {};
        if(result?.attempts){
          result.attempts = result.attempts.map(obj => obj.category_id === category.id ? { ...obj, 'questions_answered': count } : obj)
        }
      }
      return {
        ...state,
        userProgress: result,
      };
    }
    case SUBMIT_FEEDBACK_FAILED: {
      return {
        ...state,
        error: true,
      };
    }
    default:
  }
  return state;
}

export const loadProgress = (timeout = DEFAULT_REQUEST_TIMEOUT) => async (dispatch) => {
  dispatch({ type: LOAD_PROGRESS });
  await axios.get(`${domain}/api/quiz/progress`, { withCredentials: true })
              .then(result => setTimeout(() => dispatch({ type: LOAD_PROGRESS_SUCCESS, result: { ...result.data, loaded: true } }), timeout))
              .catch(error => dispatch({ type: LOAD_PROGRESS_FAILED, error }));
};

export const loadStart = (categoryId, timeout = DEFAULT_REQUEST_TIMEOUT) => async (dispatch) => {
  dispatch({ type: LOAD_START });
  await axios.post(`${domain}/api/quiz/start`, { category_id: categoryId }, { withCredentials: true })
              .then(result => setTimeout(() => dispatch({ type: LOAD_START_SUCCESS, result: { ...result.data, loaded: true } }), timeout))
              .catch(error => dispatch({ type: LOAD_START_FAILED, error }));
};

export const updateTickets = tickets => async dispatch => dispatch({ type: UPDATE_TICKETS, result: tickets });

export const updateAttemptedQuestions = data => async dispatch => dispatch({ type: UPDATE_ATTEMPTED_QUESTIONS, result: data });

export const submitFeedback = (attemptId, stars, text) => async (dispatch) => {
  await axios.post(`${domain}/api/feedback/submit/`, { stars, text: text.trim(), attempt: attemptId }, { withCredentials: true })
             .then(() => sendEvent({ category: 'Feedback', action: 'Sent', value: stars, label: text }))
             .catch(error => dispatch({ type: SUBMIT_FEEDBACK_FAILED, error }));
};
