import React, { PureComponent } from 'react';

import { Typography, Button, Box } from '@mui/material';
import styles from './ErrorPage.module.css';

export default class ErrorPage extends PureComponent {
  render() {
    return(
      <Box component='div' className={styles.errorPage}>
        <Typography className={styles.errorText}>
          Oh Zapp! Something went wrong. Click the button below to try again.
        </Typography>
        <Button className={styles.errorButton} onClick={() => window.location.reload()}> Refresh </Button>
      </Box>          
    )
  }
}
