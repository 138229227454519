import React, { PureComponent } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import { init, sendPageView } from './analytics';

import NavBar from './components/NavBar';
import EntryScreen from './pages/EntryScreen';
import CategoriesScreen from './pages/CategoriesScreen';
import QuizScreen from './pages/QuizScreen';

import { PATHS } from './helpers/enums'; 

export default class Root extends PureComponent {
  render() {
    return (
    <div>
      <CookieConsent
        overlay
        onAccept={() => {
          init();
          sendPageView();
        }}
        style={{ background: "#DC9851" }}
        buttonStyle={{ backgroundColor: "#872B3C", borderRadius: '4px', color: 'white', fontSize: '1em' }}
        expires={184}>
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <BrowserRouter>
        <Routes>
          <Route path={PATHS.HOME} element={<EntryScreen />} />
          <Route path={PATHS.CATEGORIES} element={<NavBar><CategoriesScreen /></NavBar>} />
          <Route exact path={PATHS.QUESTIONS} element={<NavBar><QuizScreen /></NavBar>} />
          <Route path="*" element={<Navigate replace to={PATHS.CATEGORIES} />} />
        </Routes>
      </BrowserRouter>
    </div>
    )}
};
