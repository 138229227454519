import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCookieConsentValue } from 'react-cookie-consent';

import { AppBar, Toolbar, Box, IconButton } from '@mui/material';
import classNames from 'classnames';
import styles from './NavBar.module.css';

import * as userActions from '../redux/modules/userStore';
import ErrorPage from './ErrorPage';
import SmallTicket from './SmallTicket';

// import NavBarLogo from '../assets/images/navBarLogo.svg';
import NavBarLogo from '../assets/images/logo/horizontal-logo-000000.png';

import { PATHS } from '../helpers/enums';

class NavBar extends PureComponent {
  static propTypes = {
    children: PropTypes.element,
    loadingCategories: PropTypes.bool,
    loadingQuestion: PropTypes.bool,
    loadingAnswer: PropTypes.bool,
    loadingProgress: PropTypes.bool,
    userError: PropTypes.bool,
    questionError: PropTypes.bool,
    loadProgress: PropTypes.func,
    userProgress: PropTypes.shape({ tickets: PropTypes.number, loaded: PropTypes.bool, attempts: PropTypes.arrayOf(PropTypes.shape({ category_id: PropTypes.number, id: PropTypes.number })) }),
  };

  static defaultProps = {
    children: React.createElement('div'),
    loadingCategories: false,
    loadingQuestion: false,
    loadingAnswer: false,
    loadingProgress: false,
    userError: false,
    questionError: false,
    loadProgress: () => {},
    userProgress: {},
  };

  componentDidMount() {
    const cookieConsent = getCookieConsentValue();
    if (!cookieConsent) return;

    const { loadingProgress, loadProgress, userProgress } = this.props;


    if (!loadingProgress && !userProgress.loaded) loadProgress();

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  };

  isError = () => {
    const { questionError, userError } = this.props;

    return questionError || userError;
  }

  isIntermediarPage = () => {
    const { loadingCategories, loadingQuestion, loadingAnswer, loadingProgress } = this.props;
    const isErrorPage = this.isError();

    return loadingCategories || loadingQuestion || loadingAnswer || loadingProgress || isErrorPage;
  }

  shouldHideTopBorder = () => {
    const isIntermediarPage = this.isIntermediarPage();
    const isCategoriesScreen = window.location.pathname === PATHS.CATEGORIES;

    return isIntermediarPage || isCategoriesScreen;
  }

  render() {
    const cookieConsent = getCookieConsentValue();
    if (!cookieConsent) return <Navigate replace to={PATHS.HOME} />;

    const { children, userProgress } = this.props;

    const child = this.isError() ? <ErrorPage /> : children;

    const hideTopBorder = this.shouldHideTopBorder();

    return(
    <Box>
      <AppBar className={styles.appBar}>
        <Toolbar className={styles.menu}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            className={styles.button}
          >
            <svg width="32" height="32" viewBox="0 0 51 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" y="0.5" width="50" height="7.81356" rx="3.90678" fill="#FFFAE8" stroke="black"/>
              <rect x="0.5" y="16.0933" width="50" height="7.81356" rx="3.90678" fill="#FFFAE8" stroke="black"/>
              <rect x="0.5" y="31.6865" width="50" height="7.81356" rx="3.90678" fill="#FFFAE8" stroke="black"/>
            </svg>
          </IconButton>
          <Link to={PATHS.CATEGORIES}>
            <Box component='img' className={styles.navbarLogo} src={NavBarLogo} alt='ZAPP'/>
          </Link>
          <SmallTicket tickets={userProgress?.tickets?.toString()}/>
        </Toolbar>
      </AppBar>
      <Box className={classNames(styles.page, { [styles.hideTopBorder]: hideTopBorder, [styles.centerContent]: this.isIntermediarPage() }) } component='div'>{child}</Box>
    </Box>
    )
  }
}

export default connect(
  state => 
    ({ 
      loadingCategories: state.questionStore.loadingCategories, 
      loadingQuestion: state.questionStore.loadingQuestion, 
      loadingAnswer: state.questionStore.loadingAnswer,
      questionError: state.questionStore.error,
      loadingProgress: state.userStore.loadingProgress,
      userError: state.userStore.error,
      userProgress: state.userStore.userProgress,
    }),
    dispatch => 
      bindActionCreators(
        { 
        ...userActions 
        }, 
    dispatch, 
))(NavBar);
