import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Rating, Typography } from '@mui/material';

import styles from './FeedbackDialog.module.css';

import * as userActions from '../redux/modules/userStore';
import * as questionActions from '../redux/modules/questionStore';

class FeedbackDialog extends PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    attemptId: PropTypes.number,
    submitFeedback: PropTypes.func,
    closeFeedbackDialog: PropTypes.func,
  };

  static defaultProps = {
    open: false,
    attemptId: 0,
    submitFeedback: () => {},
    closeFeedbackDialog: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      stars: 0,
      text: ''
    };
  };


  handleClose = () => {
    const { closeFeedbackDialog } = this.props;
    closeFeedbackDialog();
  }

  submit = () => {
    const { submitFeedback, attemptId } = this.props;
    const { stars, text }= this.state;

    submitFeedback(attemptId, stars, text);
    this.handleClose();
  }

  render() {
    const { open } = this.props;
    const { stars, text } = this.state;

    return(
      <Dialog open={open} onClose={() => this.handleClose()}>
        <DialogTitle>How would rate your experience using ArtZapp?</DialogTitle>
        <DialogContent>
          <Rating
            name='simple-controlled'
            color='warning'
            onChange={(event, newValue) => {
              this.setState({ stars: newValue });
            }}/>
          <Typography className={styles.labelFeedback}>Use this space to tell us how we can improve ArtZapp.</Typography>
          <TextField 
            autoFocus
            multiline
            margin="dense"
            id="name"
            label=""
            fullWidth
            sx={{
              '& .MuiInput-underline:before': { borderBottomColor: '#195174' },
              '& .MuiInput-underline:after': { borderBottomColor: '#195174' },
            }}
            InputLabelProps={{
              style: { color: '#195174' },
            }}
            variant="outlined"
            inputProps={{ maxLength: 500 }}
            value={text}
            onChange={event => this.setState({ text: event.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={!stars} className={styles.sendButton} onClick={() => this.submit()}>Send</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default connect(
  () => ({}),
  dispatch => 
    bindActionCreators(
      { 
      ...userActions, 
      ...questionActions,
      }, 
    dispatch, 
))(FeedbackDialog);
