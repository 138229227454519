import ReactGA from 'react-ga'
import { getCookieConsentValue } from 'react-cookie-consent';

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

export const init = () => {
  // Enable debug mode on the local development environment
  const cookieConsent = getCookieConsentValue();
  if (!cookieConsent) return;

  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  ReactGA.initialize(TRACKING_ID, { debug: isDev });
}

export const sendEvent = payload => {
  ReactGA.event(payload);
}

export const sendPageView = () => {
  const path = window.location.pathname;

  ReactGA.pageview(path);
}
