import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Typography } from '@mui/material';
import classNames from 'classnames';
import styles from './CategoriesCircle.module.css';

export default class CategoriesCircle extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    backgroundColor: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    name: '',
    backgroundColor: '',
    disabled: false,
    onClick: () => {},
  };

  render() {
    const { name, backgroundColor, disabled, onClick } = this.props;
    
    return(
      <button type='button' onClick={() => onClick()} className={classNames(styles.circleCustom, { [styles.disabled]: disabled })} style={{ backgroundColor }}>
        <div className={styles.circleTextWrapper}>
          <Typography className={styles.circleText}>{name}</Typography>
        </div>
        <div className={styles.imgWrapper}>
          <img src={`/category/${name}.png`} alt={name} className={styles.circleImage} />
        </div>
    </button>
    )
  }
}
