import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import {Typography, Box } from '@mui/material';
import styles from './SmallTicket.module.css';
import TicketBG from '../assets/images/ticket-bg.png';


export default class SmallTicket extends PureComponent {
  static propTypes = {
    tickets: PropTypes.string,
  };

  static defaultProps = {
    tickets: '0',
  };

  render() {
    const { tickets } = this.props;

    return(
      
      <div className={styles.ticketWapper}>
        <Box component='img' src={TicketBG} alt='ZAPP'/>
        <Typography className={styles.ticketNumber}>{tickets}</Typography>
      </div>
    
    )
  }
}
